import { TextInput } from "flowbite-react"
import React, { useState, useRef, useEffect } from "react"
import { Controller } from "react-hook-form"

const SearchableMultiSelect = ({ control, name, options }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedItems, setSelectedItems] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const filteredOptions = options.filter(
    (option) =>
      option.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !selectedItems.includes(option),
  )

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { value } = field // This is your form value

        useEffect(() => {
          if (JSON.stringify(value) !== JSON.stringify(selectedItems)) {
            setSelectedItems(value)
          }
        }, [value])

        const handleSelect = (item) => {
          const newSelectedItems = [...selectedItems, item]
          setSelectedItems(newSelectedItems)
          setSearchTerm("")
          setIsOpen(false)
          field.onChange(newSelectedItems) // Update the form value
        }

        const handleRemove = (item) => {
          const newSelectedItems = selectedItems.filter((i) => i !== item)
          setSelectedItems(newSelectedItems)
          field.onChange(newSelectedItems) // Update the form value
        }

        return (
          <div className="max-w-md w-full">
            <div className="relative" ref={dropdownRef}>
              <TextInput
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                  setIsOpen(true)
                }}
                onClick={() => setIsOpen(true)}
              />
              {isOpen && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                  {filteredOptions.map((option) => (
                    <li
                      key={option}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleSelect(option)}
                      onKeyUp={() => handleSelect(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="mt-2 flex flex-wrap gap-2">
              {selectedItems.map((item) => (
                <span
                  key={item}
                  className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm flex items-center"
                >
                  {item}
                  <button
                    type="button"
                    className="ml-1 text-xs font-bold focus:outline-none"
                    onClick={() => handleRemove(item)}
                  >
                    x
                  </button>
                </span>
              ))}
            </div>
          </div>
        )
      }}
    />
  )
}

export default SearchableMultiSelect
