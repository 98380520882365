import { Checkbox, Label, TextInput } from "flowbite-react"
import React, { useState, useRef, useEffect } from "react"
import { Controller } from "react-hook-form"

export function CheckboxGroup({ control, name, options }) {
  const [selectedItems, setSelectedItems] = useState([])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { value } = field

        const handleSelect = (item) => {
          const newSelectedItems = Array.from(
            new Set([...selectedItems, item.value]),
          )
          setSelectedItems(newSelectedItems)
          field.onChange(newSelectedItems)
        }

        const handleRemove = (item) => {
          const newSelectedItems = selectedItems.filter((i) => i !== item)
          setSelectedItems(newSelectedItems)
          field.onChange(newSelectedItems)
        }

        const handleCheckboxClick = (e, option) => {
          e.target.checked ? handleSelect(option) : handleRemove(option.value)
        }

        return (
          <fieldset className="flex max-w-md flex-col gap-2">
            {options.map((option) => (
              <label
                htmlFor={`${name}_${option.id}`}
                key={`${name}_${option.id}`}
                className="flex items-center gap-2"
              >
                <Checkbox
                  id={`${name}_${option.id}`}
                  name={name}
                  value={option?.value}
                  checked={value?.includes(option.value)}
                  onChange={(e) => handleCheckboxClick(e, option)}
                />
                <Label htmlFor={`${name}_${option.id}`}>{option.name}</Label>
              </label>
            ))}
          </fieldset>
        )
      }}
    />
  )
}
