import dayjs from "dayjs"
import { Button, Dropdown, Label, Popover, TextInput } from "flowbite-react"
import { isEqual } from "lodash"
import { useEffect, useRef, useState } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { CheckboxGroup } from "../../components/checkbox-group"
import CustomDatePicker from "../../components/date-picker"
import { HeroIcon } from "../../components/hero-icon"
import { RadioGroup } from "../../components/radio-group"
import SearchableMultiSelect from "../../components/searchable-multi-select"
import { dobCalculator } from "../../shared/dob-calculator"
import useDebounce from "../../shared/use-debounce-hooks"
import { useIndexQuery } from "../../shared/use-rest-query"
import { PopoverTheme } from "../../styles/flowbite-themes"

export function CalendarScreeningFilters(props) {
  const { setFilterAndSort, filterAndSort } = props

  const [open, setOpen] = useState(false)
  const [practiceStudyOptions, setPracticeStudyOptions] = useState([])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      filterByProvider: [],
      excludeReviewStatus: ["reviewed"],
      studies: [],
      minApptDate: dayjs().toDate(),
      maxApptDate: dayjs().add(2, "week").toDate(),
      minAge: 18,
      maxAge: 99,
      sortBy: "appointment_start_dt",
      groupBy: "",
    },
  })

  const {
    data: { data: practiceReferringProviders },
  } = useIndexQuery("practice_referring_providers")

  const {
    data: { data: practiceStudies },
  } = useIndexQuery("practice_studies")

  useEffect(() => {
    if (practiceStudies) {
      setPracticeStudyOptions(
        practiceStudies
          .filter((study) => study?.study?.abbreviation !== undefined)
          .map((study) => study?.study?.abbreviation),
      )
    }
  }, [practiceStudies])

  const minAgeValue = useWatch({
    control,
    name: "minAge",
  })

  const maxAgeValue = useWatch({
    control,
    name: "maxAge",
  })

  const minAppointmentDateValue = useWatch({
    control,
    name: "minApptDate",
  })

  const maxAppointmentDateValue = useWatch({
    control,
    name: "maxApptDate",
  })

  const onSubmit = async (data) => {
    const filters = [
      {
        field: "dob",
        operator_value: "onOrBefore",
        value: dobCalculator(data.minAge),
      },
      {
        field: "dob",
        operator_value: "onOrAfter",
        value: dobCalculator(data.maxAge),
      },
      {
        field: "appointment_start_dt",
        operator_value: "onOrAfter",
        value: data.minApptDate.toISOString().slice(0, -1),
      },
      {
        field: "appointment_start_dt",
        operator_value: "onOrBefore",
        value: data.maxApptDate.toISOString().slice(0, -1),
      },
    ]

    if (data?.studies?.length > 0) {
      filters.push({
        field: "study",
        operator_value: "is_any_of",
        value: data.studies,
      })
    }

    if (data?.excludeReviewStatus?.length > 0) {
      filters.push({
        field: "review_status",
        operator_value: "equals",
        value: null,
      })
    }

    if (data?.filterByProvider.length > 0) {
      filters.push({
        field: "referring_provider",
        operator_value: "is_any_of",
        value: data.filterByProvider,
      })
    }

    const filterAndSort = {
      sorting: [],
      filtering: {
        filters,
        linkOperator: "and",
      },
    }

    if (data.sortBy) {
      console.log(filterAndSort.sorting)
      filterAndSort.sorting = [
        {
          field: data.sortBy,
          sort: "desc",
        },
      ]
    }

    setFilterAndSort(filterAndSort)
  }

  const watchedFields = watch()
  const prevWatchedFields = useRef(watchedFields)

  const autoSave = useDebounce(handleSubmit(onSubmit), 500)

  useEffect(() => {
    if (!isEqual(prevWatchedFields.current, watchedFields)) {
      autoSave()
    }

    prevWatchedFields.current = watchedFields
  }, [watchedFields, autoSave])

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      theme={PopoverTheme}
      content={
        <div style={{ width: "400px", overflowY: "scroll", maxHeight: "70vh" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex w-100 flex-col gap-4 p-4 text-sm text-gray-500 dark:text-gray-400">
              <div>
                <h2 id="area-popover" className="text-base text-gray-500">
                  Filter
                </h2>
                {/* Age */}
                <div className="mt-4 block">
                  <Label htmlFor="age" value="Age" />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="m-2" style={{ width: "50%" }}>
                    <div className="mb-2 block">
                      <Label htmlFor="minAge" value="From" />
                    </div>
                    <TextInput
                      id="minAge"
                      min={18}
                      max={maxAgeValue}
                      type="number"
                      {...register("minAge")}
                    />
                  </div>
                  <div className="m-2" style={{ width: "50%" }}>
                    <div className="mb-2 block">
                      <Label htmlFor="maxAge" value="To" />
                    </div>
                    <TextInput
                      id="maxAge"
                      min={minAgeValue}
                      type="number"
                      {...register("maxAge", {
                        validate: (value) =>
                          value >= minAgeValue ||
                          "Max age must be greater than or equal to min age",
                      })}
                    />
                  </div>
                </div>
                {/* Appointment Date */}
                <div className="mt-5 block">
                  <Label htmlFor="appointmentDate" value="Appointment Date" />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="m-2" style={{ width: "50%" }}>
                    <div className="mb-2 block">
                      <Label htmlFor="minAppointmentDate" value="From" />
                    </div>
                    <CustomDatePicker
                      control={control}
                      name="minApptDate"
                      maxDate={maxAppointmentDateValue}
                    />
                  </div>
                  <div className="m-2" style={{ width: "50%" }}>
                    <div className="mb-2 block">
                      <Label htmlFor="maxAppointmentDate" value="To" />
                    </div>
                    <CustomDatePicker
                      control={control}
                      name="maxApptDate"
                      minDate={minAppointmentDateValue}
                    />
                  </div>
                </div>
                {/* Studies */}
                <div className="mt-5 mb-3 block">
                  <Label htmlFor="studies" value="Studies" />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="m-2"
                >
                  <SearchableMultiSelect
                    control={control}
                    name="studies"
                    options={practiceStudyOptions}
                  />
                </div>
                {/* Review Status */}
                <div className="mt-5 mb-3 block">
                  <Label
                    htmlFor="exclude_review_status"
                    value="Exclude Review Status"
                  />
                </div>
                <div className="m-2">
                  <CheckboxGroup
                    control={control}
                    name="excludeReviewStatus"
                    options={[
                      {
                        id: "reviewed",
                        name: "Reviewed",
                        value: "reviewed",
                      },
                    ]}
                  />
                </div>
                {/* Sort By */}
                <div className="mt-5 mb-3 block">
                  <Label htmlFor="sortBy" value="Sort By" />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="m-2"
                  key="sortByGroup"
                >
                  <RadioGroup
                    control={control}
                    name="sortBy"
                    options={[
                      {
                        id: "none",
                        name: "None",
                        value: "",
                      },
                      {
                        id: "appointmentDate",
                        name: "Appointment Date",
                        value: "appointment_start_dt",
                      },
                      {
                        id: "indication",
                        name: "Indication",
                        value: "indication",
                      },
                      {
                        id: "status",
                        name: "Status",
                        value: "status",
                      },
                      {
                        id: "provider",
                        name: "Provider",
                        value: "provider",
                      },
                    ]}
                  />
                </div>
                {/* Group By */}
                <div className="mt-5 mb-3 block">
                  <Label htmlFor="groupBy" value="Group By" />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="m-2"
                  key="groupByGroup"
                >
                  <RadioGroup
                    control={control}
                    name="groupBy"
                    options={[
                      {
                        id: "none",
                        name: "None",
                        value: "",
                      },
                      {
                        id: "appointmentDate",
                        name: "Appointment Date",
                        value: "appointment_date",
                      },
                      {
                        id: "indication",
                        name: "Indication",
                        value: "indication",
                      },
                      {
                        id: "status",
                        name: "Status",
                        value: "status",
                      },
                      {
                        id: "provider",
                        name: "Provider",
                        value: "provider",
                      },
                    ]}
                  />
                </div>
                {/* Provider */}
                <div className="mt-5 mb-3 block">
                  <Label
                    htmlFor="filterByProvider"
                    value="Filter by Provider"
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="m-2"
                >
                  <SearchableMultiSelect
                    control={control}
                    name="filterByProvider"
                    options={practiceReferringProviders}
                  />
                </div>
              </div>

              <div className="flex gap-2 justify-end">
                <Button color="gray" onClick={() => reset()}>
                  Reset
                </Button>
                {/* <Button type="submit" color="success">
                  Save
                </Button> */}
              </div>
            </div>
          </form>
        </div>
      }
    >
      <button
        type="button"
        className={
          "mr-2 inline-flex items-center p-2 pb-1.5 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white hover:cursor-default"
        }
      >
        <HeroIcon
          icon="FilterIcon"
          className={"h-5 w-7 mr-1 text-white"}
          outline={true}
        />
      </button>
    </Popover>
  )
}
