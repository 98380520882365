import { useContext } from "react"
import { Link } from "react-router-dom"
import { ButtonLeadingIcon } from "../../components/button-leading-icon"
import { HeroIcon } from "../../components/hero-icon"
import { AuthorizationContext } from "../../shared/authorization-provider"

export function Admin() {
  const { practiceId } = useContext(AuthorizationContext)

  // TODO: drive this off env.PRACTICE_ID presence?
  const multiTenantEnabled = process.env.MULTI_TENANT_ENABLED === "true"
  const networkManagementTenantId =
    process.env.NETWORK_MANAGEMENT_TENANT_PRACTICE_ID
  const networkManagement = []

  const siteManagement = []

  const contentLinks = []
  return (
    <div className="p-6">
      <h5 className="text-2xl mb-4 font-bold tracking-tight text-gray-900 dark:text-gray-200 flex flex-row">
        <HeroIcon icon="CogIcon" className="h-5 mr-2 self-center" />
        Admin
      </h5>

      <div className="grid grid-cols-1 gap-2">
        {/* Network Management */}
        {multiTenantEnabled && practiceId === networkManagementTenantId && (
          <div>
            <h2 className="text-xl font-bold mb-4">Network Management</h2>
            <div className="flex flex-col space-y-2 w-48">
              <Link className="flex flex-grow text-blue" to="/admin/users">
                <ButtonLeadingIcon
                  icon="UsersIcon"
                  className="flex-grow p-4 self-center"
                  text="Users"
                />
              </Link>
              <Link
                className="flex flex-grow text-blue mb-4"
                to="/admin/team_xrefs"
              >
                <ButtonLeadingIcon
                  icon="UserGroupIcon"
                  className="flex-grow p-4 self-center"
                  text="Team External References"
                />
              </Link>

              <Link
                className="flex flex-grow text-blue mb-4"
                to="/admin/practices"
              >
                <ButtonLeadingIcon
                  icon="HeartIcon"
                  className="flex-grow p-4 self-center"
                  text="Practices"
                />
              </Link>

              <Link
                className="flex flex-grow text-blue mb-4"
                to="/admin/sponsors"
              >
                <ButtonLeadingIcon
                  icon="OfficeBuildingIcon"
                  className="flex-grow p-4 self-center"
                  text="Sponsors"
                />
              </Link>

              <Link
                className="flex flex-grow text-blue mb-4"
                to="/admin/studies"
              >
                <ButtonLeadingIcon
                  icon="ClipboardListIcon"
                  className="flex-grow p-4 self-center"
                  text="Studies"
                />
              </Link>
              <Link
                className="flex flex-grow text-blue mb-4"
                to="/admin/indications"
              >
                <ButtonLeadingIcon
                  icon="ExclamationCircleIcon"
                  className="flex-grow p-4 self-center"
                  text="Indications"
                />
              </Link>
              <Link
                className="flex flex-grow text-blue mb-4"
                to="/admin/sync-configuration"
              >
                <ButtonLeadingIcon
                  icon="ShareIcon"
                  className="text-justify flex-grow p-4 self-center"
                  text="Sync Configuration"
                />
              </Link>
            </div>
          </div>
        )}

        {/* Site Management */}
        <div>
          <h2 className="text-xl font-bold mb-4">Site Management</h2>
          <div className="flex flex-col space-y-2 w-48">
            <Link
              className="flex flex-grow text-blue"
              to="/admin/quality-assurance"
            >
              <ButtonLeadingIcon
                icon="ClipboardIcon"
                className="flex-grow p-4 self-center"
                text="Quality Assurance"
              />
            </Link>
            <Link
              className="flex flex-grow text-blue mb-4"
              to="/admin/dashboard"
            >
              <ButtonLeadingIcon
                icon="ChartBarIcon"
                className="flex-grow p-4 self-center"
                text="DashboardDemo"
              />
            </Link>
          </div>
        </div>

        {/* Content */}
        <div>
          <h2 className="text-xl font-bold mb-4">Content Management</h2>
          <div className="flex flex-col space-y-2 w-48">
            <Link className="flex flex-grow text-blue" to="/uploads">
              <ButtonLeadingIcon
                icon="CloudUploadIcon"
                className="flex-grow p-4 self-center"
                text="Uploads"
              />
            </Link>
            <Link className="flex flex-grow text-blue mb-4" to="/admin/events">
              <ButtonLeadingIcon
                icon="RssIcon"
                className="flex-grow p-4 self-center"
                text="Events"
              />
            </Link>
            <Link
              className="flex flex-grow text-blue mb-4"
              to="/admin/comments"
            >
              <ButtonLeadingIcon
                icon="SpeakerphoneIcon"
                className="flex-grow p-4 self-center"
                text="Comments"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
